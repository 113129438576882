.@{class-prefix}-profile-head{
    position: relative;
    // .@{class-prefix}-cover-photo{
    //     // background: url(https://img.freepik.com/free-photo/blue-jeep-parking-public-zone_114579-4042.jpg?w=740&t=st=1662120643~exp=1662121243~hmac=9f7aa645d0983a05f3d35349dc303516c52999941ce13d0223a9b4edc12456c4);
    //     // background-image: url(dec-bg.png);
    //     // background-position: 50%;
    //     // background-size: cover;
    //     // min-height: 15.625rem;
    //     width: 100%;
    // }
}




.rounded {
    border-radius: 10px 10px 0px 0px;
}
.profile-info{ 
    display: flex;
}
.profile-photo{
    margin-top: -4.5rem;
    position: absolute;
    z-index: 1;
}
@media @xs{
    .@{class-prefix}-d-flex-full-center{
        justify-content: left;
    }
}
